<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <h1>Liste des pages</h1>
      </header>
      <div>
<!--        <router-link-->
<!--          to="/administration/Page/0"-->
<!--          class="btn btn-primary float-right mt-4"-->
<!--        >-->
<!--          <i class="fa fa-plus"></i>-->
<!--          <span>Nouveau</span>-->
<!--        </router-link>-->

        <div class="table-responsive col-12">
          <table class="mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-table-custom sm-2 table-logs" >
            <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Slug</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, rowIndex) in $store.state.page.list" :key="rowIndex">
              <td class="p-0">
                <router-link
                  :to="'/administration/page/'+row.slug"
                  class="btn btn-primary btn-sm btn-circle"
                >
                  <i class="fa fa-pen"></i>
                </router-link>
              </td>
              <td data-label="Title :">{{ getTitleInFrench(row) }}</td>
              <td data-label="Slug :">{{ row.slug }}</td>
            </tr>
            </tbody>
          </table>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

export default {
  name: 'PageList',
  data: () => ({
    successMessage: null,
    errors: [],
    loading: false
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    document.title = config.title + ' - Initialisation de la clé mâitre'

    this.$store.dispatch('page/getData')
  },
  methods: {
    getTitleInFrench (row) {
      if (row.translations && row.translations.fr) {
        return row.translations.fr.title
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.btn.btn-primary {
  color: #fff!important;
}
.btn-circle.btn-sm {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 4px 0 0 7px;

  i{
    font-size: 1em;
  }
}
</style>
